import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function getAcidFromLocation(location) {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get("acid");
}

export const useAcid = () => {
  const location = useLocation();
  const acid = getAcidFromLocation(location) || sessionStorage.getItem("acid");

  useEffect(() => {
    const acidFromStorage = sessionStorage.getItem("acid");
    if (acidFromStorage !== acid) {
      sessionStorage.setItem("acid", acid);
    }
  }, [acid]);

  return { acid };
};
