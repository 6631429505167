import React from "react";

export const ArrowRightIcon = (props) => (
  <svg
    width="17"
    height="12"
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.7458 5.9208L16.4158 5.5758L11.1808 0.355798C11.0645 0.213298 10.8789 0.147673 10.6989 0.188923C10.5208 0.230173 10.3802 0.370798 10.3389 0.548923C10.2977 0.728923 10.3633 0.914549 10.5058 1.0308L14.9158 5.4408L0.740789 5.4408C0.568289 5.43892 0.407038 5.52892 0.318914 5.67892C0.232664 5.82892 0.232664 6.01267 0.318914 6.16267C0.407037 6.31267 0.568289 6.40267 0.740789 6.4008L14.9158 6.4008L10.5058 10.8108C10.3633 10.927 10.2977 11.1127 10.3389 11.2927C10.3802 11.4708 10.5208 11.6114 10.6989 11.6527C10.8789 11.6939 11.0645 11.6283 11.1808 11.4858L16.4158 6.2658L16.7458 5.9208Z"
      fill="currentColor"
    />
  </svg>
);
