import React, { useEffect } from "react";
import styles from "./VisualPage.module.css";
import HeartIcon from "../../assets/common/heart-outline.svg";
import HeartFillIcon from "../../assets/common/heart-fill.svg";
import { usePassions } from "../../lib/hooks/use-passions";
import { useNavigate, useLocation } from "react-router-dom";
import { useAcid } from "../../lib/hooks/use-acid";
import {
  getAnalyticsPageName,
  sendAnalyticsPageViewEvent,
  sendAnalyticsSubmitEvent,
} from "../../utils/adobe-data-layer-utils";
import { Heading, BtnUi } from "~/components";

const data = [
  {
    label: "Golf",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/rz-okarz-golf-ball-20922?wid=316&fit=constrain",
    passion: "golf",
  },
  {
    label: "Beach",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/mc-tkgmc-beach-20758?wid=316&fit=constrain",
    passion: "beach",
  },
  {
    label: "Winter Sports",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/tx-szgjf-skiing-31248?wid=316&fit=constrain",
    passion: "ski",
  },
  {
    label: "Culinary",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/fp-aklfp-wine-dinner-20235?wid=316&fit=constrain",
    passion: "culinary",
  },
  {
    label: "Culture",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/ak-dpsav-balinese-dinner-20316?wid=316&fit=constrain",
    passion: "culture",
  },
  {
    label: "Music",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/mc-tricc-jazz-musicians-23095?wid=316&fit=constrain",
    passion: "music",
  },
  {
    label: "Spa",
    image:
      "https://cache.marriott.com/is/image/marriotts7prod/50514591-espa_privatespasuite_outdoor_sized_tile?wid=316&fit=constrain",
    passion: "spa",
  },
];

export const VisualPage = ({
  mode = "standard",
  experienceType = "visual",
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { acid } = useAcid();
  const {
    selectPassion,
    unselectPassion,
    isPassionSelected,
    isAnyPassionSelected,
    savePassionsToServer,
    getSelectedPassionsAsArray,
  } = usePassions(experienceType);

  useEffect(() => {
    if (mode === "derived") {
      const pageName = getAnalyticsPageName(
        location,
        "marriott",
        "derived-visual",
      );
      sendAnalyticsPageViewEvent(pageName);
    }
  }, []);

  const handlePassionClick = (passion) => {
    if (isPassionSelected(passion)) {
      unselectPassion(passion);
    } else {
      selectPassion(passion);
    }
  };

  const handleSubmit = async () => {
    sendAnalyticsSubmitEvent(getSelectedPassionsAsArray());
    await savePassionsToServer(acid, experienceType);
    const explore =
      mode === "standard" ? "/travel/explore" : "/derived-visual/explore";
    navigate(explore);
  };

  let header = (
    <div className={styles.header}>
      <Heading type="h1" text="What’s your travel style?" />
      <p className={styles.subheader}>
        Heart the photos that represent your interests and passions to unlock
        personalized recommendations and offers.
      </p>
    </div>
  );
  if (mode === "derived") {
    header = (
      <div className={styles.header}>
        <Heading type="h1" text="Tell us what else you’re interested in." />
      </div>
    );
  }

  return (
    <>
      {header}
      <div className={styles.cardGrid} data-mode={mode}>
        {data
          .filter((d) => {
            if (mode === "derived") {
              return d.passion !== "golf";
            }

            return true;
          })
          .map((d) => (
            <div
              className={`${styles.card} custom_click_track`}
              data-custom_click_track_value={`Travel|${d.label}|internal`}
              key={d.label}
              onClick={() => handlePassionClick(d.passion)}
            >
              <img src={d.image} alt="Card image" />
              <div className={styles.cardContent}>
                <img
                  className={styles.cardIcon}
                  src={isPassionSelected(d.passion) ? HeartFillIcon : HeartIcon}
                  alt="heart icon"
                />
                <span className={styles.cardText}>{d.label}</span>
              </div>
            </div>
          ))}
      </div>
      <div className={styles.buttonContainer}>
        <BtnUi
          className={`${styles.explore} custom_click_track`}
          data-custom_click_track_value={`Travel Footer|Save Continue|internal`}
          disabled={!isAnyPassionSelected()}
          onClick={handleSubmit}
        >
          Save & Continue
        </BtnUi>
      </div>
    </>
  );
};
