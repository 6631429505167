import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useCampaignParams } from "../../lib/hooks/use-campaign-params";
import {
  sendAnalyticsPageViewEvent,
  getAnalyticsPageName,
} from "../../utils/adobe-data-layer-utils";

const PageViewTracking = () => {
  const location = useLocation();
  const { cSource, expId } = useCampaignParams();

  useEffect(() => {
    const pageName = getAnalyticsPageName(location, cSource, expId);
    sendAnalyticsPageViewEvent(pageName);
  }, [location]); // Run this effect whenever the location changes

  return null;
};

export default PageViewTracking;
