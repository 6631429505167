import React from "react";
import classNames from "classnames";
import styles from "./Heading.module.css";

export const Heading = ({ type: Tag, text, children, className, ...rest }) => {
  return (
    <Tag className={classNames(styles.heading, className)} {...rest}>
      {text ?? children}
    </Tag>
  );
};
