import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { BtnUi } from "../BtnUi";
import styles from "./BtnBack.module.css";

export const BtnBack = ({
  text,
  children,
  trackValue,
  className,
  type = "filled",
}) => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);
  const classNames = useMemo(() => {
    // Style modifiers
    const style = styles[`btnBack_${type}`];

    return [styles.btnBack, style, "custom_click_track", className]
      .filter(Boolean)
      .join(" ");
  }, [className, type]);

  return (
    <BtnUi
      className={classNames}
      data-custom_click_track_value={`${trackValue}|internal`}
      onClick={goBack}
    >
      {text ?? children ?? "Go Back"}
    </BtnUi>
  );
};
