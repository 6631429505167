import React, { useEffect } from "react";
import { Icon, BtnUi } from "~/components";
import { VideoContainer } from "../VideoContainer";
import { useLayoutStore } from "~/store";
import styles from "./Survey.module.css";
import logoImage from "~/assets/images/marriott-logo-reverse.png";

export const Survey = ({ data, next }) => {
  const { isResetting, setShowHeader, setContentClassName } = useLayoutStore();
  const { passionName, iconName, videoUrl, posterUrl } = data;

  useEffect(() => {
    if (isResetting) {
      return;
    }

    setShowHeader(false);
    setContentClassName(styles.container);
  }, [isResetting]);

  return (
    <div className={styles.container}>
      <div className={styles.survey}>
        <div className={styles.surveyLogoWrapper}>
          <img
            className={styles.surveyLogo}
            src={logoImage}
            alt="Marriott logo"
          />
        </div>
        <section className={styles.surveyBox}>
          <VideoContainer
            className={styles.surveyVideo}
            src={videoUrl}
            poster={posterUrl}
          />
          <div className={styles.surveyInfo}>
            <div className={styles.surveyIcon}>
              <Icon name={iconName} />
            </div>
            <div className={styles.surveyText}>I’m passionate about...</div>
            <div className={styles.surveyPassion}>{passionName}</div>
            <div className={styles.surveyBtnGroup}>
              <BtnUi
                className={styles.surveyBtn}
                onClick={next.bind(null, false)}
              >
                <span>No</span>
                <Icon name="dislike" className={styles.surveyBtnIcon} />
              </BtnUi>
              <BtnUi
                className={styles.surveyBtn}
                onClick={next.bind(null, true)}
              >
                <span>Yes</span>
                <Icon name="like" className={styles.surveyBtnIcon} />
              </BtnUi>
            </div>
            <BtnUi
              className={styles.surveySkip}
              onClick={next.bind(null, null)}
            >
              <span>Skip</span>
              <Icon name="arrowRight" className={styles.surveySkipIcon} />
            </BtnUi>
          </div>
        </section>
      </div>
    </div>
  );
};
