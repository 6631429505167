import React from "react";
import styles from "./Footer.module.css";

export const linkList = [
  { label: <div className="ot-sdk-show-settings">Tracking Preferences</div> },
  {
    label: "Terms of Use",
    url: "https://www.marriott.com/about/terms-of-use.mi",
  },
  {
    label: "Program Terms & Conditions",
    url: "https://www.marriott.com/loyalty/terms/default.mi",
  },
  {
    label: "Privacy Center",
    url: "https://www.marriott.com/about/privacy.mi",
  },
  {
    label: (
      <span>
        <img
          src="https://cache.marriott.com/aka-fonts/ccpa/privacyoptions29x14.png"
          alt="Privacy choces icon"
          className={styles.privacyChoicesImg}
        />
        Your Privacy Choices
      </span>
    ),
    url: "https://www.marriott.com/about/your-privacy-rights.mi",
  },
];
