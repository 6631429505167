import React from "react";

export const GolfIcon = (props) => (
  <svg
    width="24"
    height="34"
    viewBox="0 0 24 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.3333 25.3334V28.6667H17C15.1666 28.6667 13.6666 30.1667 13.6666 32V33.6667H10.3333V32C10.3333 30.1667 8.83331 28.6667 6.99998 28.6667H3.66665V25.3334H20.3333ZM12 0.333374C18.45 0.333374 23.6666 5.55004 23.6666 12C23.6666 18.45 18.45 23.6667 12 23.6667C5.54998 23.6667 0.333313 18.45 0.333313 12C0.333313 5.55004 5.54998 0.333374 12 0.333374ZM12 3.66671C7.39998 3.66671 3.66665 7.40004 3.66665 12C3.66665 16.6 7.39998 20.3334 12 20.3334C16.6 20.3334 20.3333 16.6 20.3333 12C20.3333 7.40004 16.6 3.66671 12 3.66671ZM8.66665 8.66671C9.58712 8.66671 10.3333 9.4129 10.3333 10.3334C10.3333 11.2538 9.58712 12 8.66665 12C7.74617 12 6.99998 11.2538 6.99998 10.3334C6.99998 9.4129 7.74617 8.66671 8.66665 8.66671ZM15.3333 8.66671C16.2538 8.66671 17 9.4129 17 10.3334C17 11.2538 16.2538 12 15.3333 12C14.4128 12 13.6666 11.2538 13.6666 10.3334C13.6666 9.4129 14.4128 8.66671 15.3333 8.66671ZM12 5.33337C12.9205 5.33337 13.6666 6.07957 13.6666 7.00004C13.6666 7.92052 12.9205 8.66671 12 8.66671C11.0795 8.66671 10.3333 7.92052 10.3333 7.00004C10.3333 6.07957 11.0795 5.33337 12 5.33337Z"
      fill="currentColor"
    />
  </svg>
);
