import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useLayoutStore } from "~/store";
import { BtnUi } from "~/components";
import "../styles/LoginPage.css";
import styles from "../styles/RenderCampaign.module.css";
import loginPageStyles from "~/pages/LoginPage/LoginPage.module.css";
import logoImage from "../assets/images/marriott-logo.png";

function LoginPage() {
  const location = useLocation();
  const { isResetting, setShowHeader, setContentClassName } = useLayoutStore();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isResetting) {
      return;
    }

    setShowHeader(false);
    setContentClassName(loginPageStyles.content);
  }, [isResetting]);

  const handleLogin = (e) => {
    setLoading(true);
    e.preventDefault();

    if (!username || !password) {
      setError("Please fill in both fields");
      setLoading(false);
      return;
    }

    // Mock authentication logic
    if (password !== "marriott") {
      setError('Invalid password. Please use password "marriott" for now.');
      setLoading(false);
      return;
    }

    console.log(
      "Authentication successful. Setting username '" +
        username +
        "' to session storage.",
    );
    sessionStorage.setItem("acid", username);

    console.log(
      "User will be redirected to campaign page with campaign parameters preserved.",
    );
    const searchParams = new URLSearchParams(location.search);
    const queryParamString = searchParams.toString();
    const destinationUrl = "#/campaign?" + queryParamString;
    console.log(
      "Redirecting user to Campaign Page using the following URL:",
      destinationUrl,
    );
    setLoading(false);
    window.location.href = destinationUrl;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.container}>
      <div className="login-page login-container">
        <img src={logoImage} alt="Marriott Logo" className="logo" />
        <h3 className="welcome-title">Welcome to Marriott Bonvoy</h3>
        <h4 className="signin-title">Sign In</h4>
        <form onSubmit={handleLogin}>
          <div className="form-field-container">
            <label htmlFor="username" className="input-label">
              Email or Member Number
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="input-field"
            />
          </div>
          <div className="form-field-container password-container">
            <label htmlFor="password" className="input-label">
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-field"
            />
            <span
              className="trailing-element"
              tabIndex="0"
              role="button"
              aria-label="toggle Password"
              aria-pressed={showPassword}
              onClick={togglePasswordVisibility}
            >
              <span
                aria-atomic="true"
                className={
                  showPassword ? "icon-visibility-on" : "icon-visibility-off"
                }
              ></span>
            </span>
          </div>
          {error && <p className="error">{error}</p>}
          <div className="form-field-container">
            <BtnUi
              type="submit"
              className="signin-btn"
              showProgressText={false}
              loading={loading}
            >
              Sign In
            </BtnUi>
          </div>
        </form>
        <p className="beta-note">
          For now, please use any email/member number and password "
          <strong>marriott</strong>" to authenticate.
        </p>
        <a
          className="forgot-password"
          href="https://www.marriott.com/forgotPassword.mi"
        >
          Forgot password
        </a>
      </div>
    </div>
  );
}

export default LoginPage;
