import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useLayoutStore } from "~/store";
import RightChevronCircle from "../../assets/common/right-cheveron-circle.svg";
import styles from "./ExplorePage.module.css";
import { exploreData } from "./explore-data";
import Back from "../../assets/follow/icons/back.svg";
import { ExploreBanner } from "./ExploreBanner";
import { usePassions } from "../../lib/hooks/use-passions";
import { Heading, BtnBack } from "../../components";

const passionsParams = [
  "golf",
  "beach",
  "ski",
  "culinary",
  "culture",
  "music",
  "spa",
];

function ExploreCard({ passionType }) {
  const passion = exploreData[passionType].slides[0];

  return (
    <a
      className={`${styles.link} custom_click_track`}
      data-custom_click_track_value={`Explore|${passion.analyticsDesc}|internal`}
      href={passion.url}
    >
      <div className={styles.card}>
        <img className={styles.img} src={passion.image} alt="" />
        <div className={styles.content}>
          <div className={styles.title}>
            <h2>{passion.headline}</h2>
            <img src={RightChevronCircle} alt="" />
          </div>
          <p>{passion.label}</p>
        </div>
      </div>
    </a>
  );
}

export default function ExplorePage({ showBanner = false, experienceType }) {
  const [searchParams] = useSearchParams();
  const { isPassionSelected, getPassionValue } = usePassions(experienceType);
  const { isResetting, setContentClassName } = useLayoutStore();

  useEffect(() => {
    if (isResetting) {
      return;
    }

    setContentClassName(styles.parentContent);
  }, [isResetting]);

  const passionFromParameter = searchParams.get("passion");
  const passions = (passionFromParameter && passionsParams.includes(passionFromParameter)) ? 
  [{
    passion: passionFromParameter,
    taps: getPassionValue(passionFromParameter),
    style: getPassionValue(passionFromParameter) > 1 ? styles.love : styles.like,
  }] 
  : passionsParams.map((p) => {
      if (isPassionSelected(p)) {
        const taps = getPassionValue(p);
        return {
          passion: p,
          taps: getPassionValue(p),
          style: taps > 1 ? styles.love : styles.like,
        };
      }
      return null;
    })
    .filter((p) => p != null)
    .sort((a, b) => b.taps - a.taps);

  return (
    <>
      <BtnBack
        trackValue="Explore Header|Back to Experience"
        type="inversion"
        className={styles.back}
      >
        <img src={Back} alt="back icon" />
        Back
      </BtnBack>
      {showBanner && <ExploreBanner passions={passions} />}
      <Heading type="h1" text="Explore your passions" />
      <p className={styles.subheader}>
        Discover experiences that inspire your next adventure and
        ignite your passions with Marriott Bonvoy®.
      </p>
      <div className={styles.wrapper}>
        <div className={styles.list}>
          {passions.map((p) => (
            <ExploreCard key={p.passion} passionType={p.passion} />
          ))}
        </div>
      </div>
    </>
  );
}
