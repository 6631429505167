/**
 * Pushes the AMENITIES_SUBMITTED event to Adobe Data Layer.
 *
 * @param {Array} selectedPassions - The list of selected passions to send in the amenities array.
 */
export const sendAnalyticsSubmitEvent = (selectedPassions) => {
  console.log("Sending selected passions to analytics: " + selectedPassions);
  const adobeDataLayer = window.adobeDataLayer || [];
  adobeDataLayer.push({
    event: "AMENITIES_SUBMITTED",
    amenities: selectedPassions,
  });
};

/**
 * Pushes the PAGE_VIEW event to Adobe Data Layer.
 *
 * @param {Array} pageName - Сustom pagename. Property is optional.
 */
export const sendAnalyticsPageViewEvent = (pageName) => {
  const adobeDataLayer = window.adobeDataLayer || [];
  adobeDataLayer.push({
    event: "PAGEVIEW",
    ...(pageName && { custom_pagename: pageName }),
  });
  console.log("PAGEVIEW event fired for:", pageName);
};

export const addUserDataToAnalytics = (acid) => {
  if (acid) {
    console.log(
      "Adding the following ACID info to be tracked by analytics: " + acid,
    );
    adobeDataLayer.push({
      USER: {
        acid: acid,
      },
    });
  }
};

const PAGE_NAME_MAPPING = {
  "/campaign": "Experience Page",
  "/multiCampaign": "Multi-Experience Page",
  "/share/explore": "Explore Page (Bubbles Experience)",
  "/follow/explore": "Explore Page (Follow Experience)",
  "/travel/explore": "Explore Page (Visual Experience)",
  "/derived-visual/explore": "Explore Page (Derived Visual Experience)",
  "/login": "Login Page",
};

export const getAnalyticsPageName = (location, cSource, expId) => {
  const pageName = PAGE_NAME_MAPPING[location.pathname] || location.pathname;
  pageName =
    location.pathname === "/campaign" && cSource && expId
      ? `Experience Page (${cSource} ${expId})`
      : pageName;
  return pageName;
};
