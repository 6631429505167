import classNames from "classnames";
import { create } from "zustand";

const defaultStore = {
  showHeader: true,
  showFooter: true,
  contentClassName: "",
};

export const useLayoutStore = create((set) => ({
  ...defaultStore,

  isResetting: false,
  resetLayoutStore: () => set({ ...defaultStore, isResetting: true }),
  completeReset: () => set({ isResetting: false }),

  setShowHeader: (showHeader) => set(() => ({ showHeader })),
  setShowFooter: (showFooter) => set(() => ({ showFooter })),
  setContentClassName: (...classes) =>
    set(() => ({ contentClassName: classNames(...classes) })),
}));
