import React, { useState } from "react";
import styles from "./DerivedVisualPage.module.css";
import { usePassions } from "../../lib/hooks/use-passions";
import { useAcid } from "../../lib/hooks/use-acid";
import { VisualPage } from "../visual/VisualPage";
import { Heading, BtnUi } from "~/components";
import { sendAnalyticsSubmitEvent } from "../../utils/adobe-data-layer-utils";

export const DerivedVisualPage = () => {
  const experienceType = "derived-visual";
  const { selectPassion, unselectPassion, savePassionsToServer, getSelectedPassionsAsArray } = usePassions(experienceType);
  const [showVisualPage, setShowVisualPage] = useState(false);
  const { acid } = useAcid();

  const handlePassionClick = async (passion) => {
    if (passion) {
      selectPassion("golf");
      sendAnalyticsSubmitEvent(getSelectedPassionsAsArray());
    } else {
      unselectPassion("golf");
    }
    await savePassionsToServer(acid, experienceType);
    setShowVisualPage(true);
  };

  if (showVisualPage) {
    return <VisualPage mode="derived" experienceType={experienceType} />;
  }

  return (
    <>
      <div className={styles.header}>
        <Heading type="h1" text="Are you are golfer?" />
        <p className={styles.subheader}>
          Select the heart below if you’re passionate about golf and 
          want to receive curated travel recommendations.
        </p>
      </div>
      <div className={styles.imgContainer}>
        <img
          src="https://cache.marriott.com/is/image/marriotts7prod/mc-wasbd-golf-course-35141?wid=360&fit=constrain"
          alt="image of a golfer"
          className={styles.img}
        />
      </div>
      <div className={styles.buttonContainer}>
        <BtnUi
          className={`${styles.yesBtn} custom_click_track`}
          data-custom_click_track_value={`Golf|Yes Golf|internal`}
          onClick={() => handlePassionClick(true)}
        >
          Yes, I’m a golfer
        </BtnUi>
        <BtnUi
          className={`${styles.noBtn} custom_click_track`}
          data-custom_click_track_value={`Golf|No Golf|internal`}
          onClick={() => handlePassionClick(false)}
        >
          No, I’m a not interested in golf
        </BtnUi>
      </div>
    </>
  );
};
