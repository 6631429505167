import { useLocation } from "react-router-dom";

export function useCampaignParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cSource = searchParams.get("cSource");
  const expId = searchParams.get("expId");
  const testParam = searchParams.get("test");
  const channelSrc = searchParams.get("channelSrc");

  return { cSource, expId, testParam, channelSrc };
}