import React from "react";
import classNames from "classnames";
import styles from "./VideoContainer.module.css";

export const VideoContainer = ({ className, src, poster, ...rest }) => {
  return (
    <div className={classNames(styles.container, className)} {...rest}>
      <video
        className={styles.video}
        src={src}
        poster={poster}
        preload="auto"
        autoPlay
        muted
        loop
      />
    </div>
  );
};
