import React from 'react';
import styles from "../styles/LoadingPage.module.css";
import logoImage from "../assets/images/marriott-logo.png";
import Progress from '../assets/common/circular-progress.svg';

export default function LoadingPage() {
    return <div className={styles.container}>
        <div className={styles.inner}>
            <div className={styles.content}>
                <img className={styles.logo} src={logoImage} alt="Marriott Bonvoy logo"/>
                <img className="spinner" src={Progress} alt=""/>
                <p>
                    One moment while we redirect you...
                </p>
            </div>
        </div>
    </div>
}
