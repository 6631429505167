import golfVideo from "~/assets/visual/video-passions-1.mov";
import golfPoster from "~/assets/visual/video-passions-1.png";

export const surveyData = [
  {
    passionName: "music",
    iconName: "golf",
    videoUrl: golfVideo,
    posterUrl: golfPoster,
  },
  {
    passionName: "arts & culture",
    iconName: "golf",
    videoUrl: golfVideo,
    posterUrl: golfPoster,
    passionId: "culture",
  },
  {
    passionName: "beach",
    iconName: "golf",
    videoUrl: golfVideo,
    posterUrl: golfPoster,
  },
  {
    passionName: "golf",
    iconName: "golf",
    videoUrl: golfVideo,
    posterUrl: golfPoster,
  },
  {
    passionName: "spa",
    iconName: "golf",
    videoUrl: golfVideo,
    posterUrl: golfPoster,
  },
  {
    passionName: "culinary",
    iconName: "golf",
    videoUrl: golfVideo,
    posterUrl: golfPoster,
  },
  {
    passionName: "ski",
    iconName: "golf",
    videoUrl: golfVideo,
    posterUrl: golfPoster,
  },
];
