import styles from "./ExplorePage.module.css";
import React from "react";
import BeachIcon from "../../assets/share/icon/beach.svg";
import CultureIcon from "../../assets/share/icon/culture.svg";
import CulinaryIcon from "../../assets/share/icon/culinary.svg";
import GolfIcon from "../../assets/share/icon/golf.svg";
import MusicIcon from "../../assets/share/icon/music.svg";
import SkiIcon from "../../assets/share/icon/ski.svg";
import SpaIcon from "../../assets/share/icon/spa.svg";

const icons = {
  beach: BeachIcon,
  culture: CultureIcon,
  culinary: CulinaryIcon,
  golf: GolfIcon,
  music: MusicIcon,
  ski: SkiIcon,
  spa: SpaIcon,
};

export function ExploreBanner({ passions }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header} data-passions={`${passions.length}`}>
        {passions.map((p, index) => (
          <div className={`${styles.icon} ${p.style}`} key={index}>
            <img src={icons[p.passion]} alt={`${p.passion} icon`} />
          </div>
        ))}
      </div>
    </div>
  );
}
