import React from "react";
import styles from "./ProgressUi.module.css";

export const ProgressUi = ({ text = "Saving", showText = true }) => {
  return (
    <div className={styles.progress}>
      <span className={styles.progressCircle}></span>
      {showText && <span className={styles.progressText}>{text}</span>}
    </div>
  );
};
