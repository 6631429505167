/**
 * Method to construct iFrame URL based on provided parameters
 * @param {string} cSource - Campaign Source, required
 * @param {string} cid - Campaign ID, required
 * @param {boolean} draft - Draft mode, optional
 * @param {string} channelSrc - Channel Source, optional
 * @param {string} acid - Alternate Customer ID, optional
 * @returns {string|null} - Constructed iFrame URL or null if any required parameter is missing
 */
export const buildIFrameUrl = (cSource, cid, draft, channelSrc, acid) => {
  if ((cSource && cSource.toLowerCase() !== "jebbit") || !cid) {
    console.error("Invalid or missing required parameters: cSource or cid.");
    return null;
  }

  let iFrameUrl = `https://marriott.jebbit.com/${cid}`;
  let queryParamAdded = false;

  if (draft && draft.toLowerCase() === "true") {
    iFrameUrl += "?draft=true";
    queryParamAdded = true;
  }

  if (channelSrc) {
    iFrameUrl += queryParamAdded ? `&L=${channelSrc}` : `?L=${channelSrc}`;
    queryParamAdded = true;
  }

  if (acid) {
    iFrameUrl += queryParamAdded ? `&uid=${acid}` : `?uid=${acid}`;
  }

  return iFrameUrl;
};

export function setCookie(name, value, mins) {
  let expires = "";
  if (mins) {
    const date = new Date();
    date.setTime(date.getTime() + mins * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
