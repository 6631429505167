import React from "react";
import { FollowExperiencePage } from "../follow/FollowExperiencePage";
import { BubblePage } from "../share/BubblePage";
import { VisualPage } from "../visual/VisualPage";
import { DerivedVisualPage } from "../derived-visual/DerivedVisualPage";
import { VideoPassions } from "~/pages/CampaignPage/VideoPassions";

export const marriottCampaigns = {
  campaigns: [
    {
      uuid: "52713a2d-596c-4f80-9ad9-e535a0e9dbce",
      campaignPage: <FollowExperiencePage />,
    },
    {
      uuid: "6ea7d529-0f69-4531-8c6e-6b948b736379",
      campaignPage: <BubblePage />,
    },
    {
      uuid: "follow",
      campaignPage: <FollowExperiencePage />,
    },
    {
      uuid: "share",
      campaignPage: <BubblePage />,
    },
    {
      uuid: "travel",
      campaignPage: <VisualPage />,
    },
    {
      uuid: "golf",
      campaignPage: <DerivedVisualPage />,
    },
    {
      uuid: "video",
      campaignPage: <VideoPassions />,
    },
  ],
};
