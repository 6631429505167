import Golf1 from "../../assets/follow/images/explore/jhmrz-plantation-course-1256-hor-wide.avif";
import Golf2 from "../../assets/follow/images/explore/lontp_golf-course-view2-65bd0113c11d9.jpg";
import Golf3 from "../../assets/follow/images/explore/n98b0XwHSou8IP0z5YoF_autoKKYAKls.1214389.jpg";
import Golf4 from "../../assets/follow/images/explore/coligny-beach-park-hilton-head-south-carolina_Wide-Hor.jpeg";
import Beach1 from "../../assets/follow/images/explore/mrkfl-attraction-beach-0240_Classic-Hor.jpeg";
import Beach2 from "../../assets/follow/images/explore/ZAFdb5XfREK7UoKoaA79_GettyImages-1086230286_Medium.jpeg";
import Beach3 from "../../assets/follow/images/explore/Olundeniz-Beach-GI-1309814597.jpg";
import Mountain1 from "../../assets/follow/images/explore/gvawh-exterior-1651-hor-wide.avif";
import Mountain2 from "../../assets/follow/images/explore/GI-523306740-WinterParkHeader.jpg";
import Mountain3 from "../../assets/follow/images/explore/MI_Westin_Whistler.jpg";
import Mountain4 from "../../assets/follow/images/explore/ALMY_D7JNWA_BackcountrySkiBanff.jpg";
import Culinary1 from "../../assets/follow/images/explore/4rYDFp9xRg2cRv9zAw9F_pdtre.1339773.jpg";
import Culinary2 from "../../assets/follow/images/explore/U0qTz1otTImAL56TbOU5_pdtrf.1136448.jpg";
import Culinary3 from "../../assets/follow/images/explore/asheville-hero-destinations.jpg";
import Culture1 from "../../assets/follow/images/explore/the-vienna-state-opera-215858.jpg";
import Culture2 from "../../assets/follow/images/explore/bodbr-gina-bar-5619-hor-clsc.avif";
import Culture3 from "../../assets/follow/images/explore/OIYHol2QGaLaSVn3TTDw_GettyImages-149615583_Medium.jpeg";
import Culture4 from "../../assets/follow/images/explore/cathedral-basilica-st-francis-assisi-santa-fe-new-mexico_Wide-Hor.jpeg";
import Music1 from "../../assets/follow/images/explore/adA7RufmTkSjOGuV1quI_GettyImages-1904527057.jpg";
import Music2 from "../../assets/follow/images/explore/Friends-Music-Festival.jpg";
import Music3 from "../../assets/follow/images/explore/marquee-web.jpg";
import Spa1 from "../../assets/follow/images/explore/St_Regis_Maldives_Spa_strMLEXRsp.jpg";
import Spa2 from "../../assets/follow/images/explore/mc-hktmk-hktmk-lobby-entrance13411-86308_Pano-Hor.jpeg";
import Spa3 from "../../assets/follow/images/explore/rckpalu_00148_conversion_Wide-Hor.jpeg";
import Spa4 from "../../assets/follow/images/explore/Mariott-Puerto-Vallarta-PVRWHex.jpg";
import Spa5 from "../../assets/follow/images/explore/dpswh-away-spa-1746-hor-pano.webp";

export const exploreData = {
  golf: {
    headline: "Explore Your Passion for Golf",
    subheader:
      "Discover destinations with world class courses, all inclusive amenities and more!",
    description:
      "We will connect you to Marriott's golf courses, destinations, and epic experiences.",
    analyticsDesc: "Golf Resort",
    slides: [
      {
        label: "Find beautiful places to stay and play.",
        image: Golf1,
        url: "https://www.marriott.com/en-us/resorts/golf-resorts",
      },
      {
        label:
          "Enjoy your favorite game, meet legends, and sharpen your skills.",
        image: Golf2,
        url: "https://moments.marriottbonvoy.com/en-us/moments/category/sports",
      },
      {
        label:
          "Discover Hilton Head Island - Enjoy this low country island's laid-back lifestyle and world-class golfing.",
        image: Golf4,
        url: "https://www.marriott.com/en/destinations/hilton-head-island.mi",
      },
      {
        label:
          "From day trips to private tours, discover more with Marriott Bonvoy's collection of amazing local experiences.",
        image: Golf3,
        url: "https://activities.marriott.com/search?term=golf",
      },
    ],
  },
  beach: {
    headline: "Explore Your Passion for Beach and Water Sports",
    subheader: "",
    description:
      "Discover the best of beach travel, from beachfront stays to amped-up activities.",
    analyticsDesc: "Beach Resort",
    slides: [
      {
        label:
          "Discover Beach Resorts - Refreshing swims, beach walks and sun-kissed resort days are here.",
        image: Beach1,
        url: "https://www.marriott.com/en-us/resorts/beach-resorts",
      },
      {
        label:
          "Beach Tours & Activities - Get out on the water with exciting excursions, water sport lessons, and other curated adventures.",
        image: Beach2,
        url: "https://activities.marriott.com/north_america/usa-DJBHT2?category=1ee8f7ed-41e8-6534-984f-81176589e86e",
      },
      {
        label:
          "The World’s Most Exceptional Beaches - Savor your downtime on these extraordinary beaches in Europe, the Middle East and Africa.",
        image: Beach3,
        url: "https://traveler.marriott.com/beach-travel/best-beaches-europe-middle-east-africa/",
      },
    ],
  },
  ski: {
    headline: "Explore Your Passion for Mountain and Winter Sports",
    subheader: "",
    description:
      "Take travel to new heights with scenic stays and snowy sporting adventures.",
    analyticsDesc: "Ski Resort",  
    slides: [
      {
        label: "Seek snowy peaks at these amazing hotels.",
        image: Mountain1,
        url: "https://www.marriott.com/en-us/resorts/ski-resorts",
      },
      {
        label: "9 U.S. National Parks with Epic Winter Sports",
        image: Mountain2,
        url: "https://traveler.marriott.com/national-parks/national-parks-winter-sports/",
      },
      {
        label: "Find Your Chill at These 6 Cold-Weather Resorts",
        image: Mountain3,
        url: "https://traveler.marriott.com/tips-and-trends/best-cold-weather-resorts/",
      },
      {
        label: "Ski, snowboard, and more on a curated tour.",
        image: Mountain4,
        url: "https://activities.marriott.com/search?term=skiing",
      },
    ],
  },
  culinary: {
    headline: "Explore Your Passion for Unforgettable Culinary Experiences",
    subheader: "",
    description:
      "Discover everything from Michelin-starred restaurants to home rentals with gourmet kitchens.",
    analyticsDesc: "Wine and Dine",  
    slides: [
      {
        label:
          "Explore global dining and drinking experiences with Marriott Bonvoy.",
        image: Culinary1,
        url: "https://www.marriott.com/en-us/dining",
      },
      {
        label:
          "Bid Marriott Bonvoy Points on curated private wine tastings, Michelin-starred restaurant experiences, cooking classes, and more.",
        image: Culinary2,
        url: "https://moments.marriottbonvoy.com/en-us/moments/category/culinary",
      },
      {
        label:
          "Discover Asheville, North Carolina - Famous for its proximity to Blue Ridge Mountain trails, Asheville ranks among the foodie destinations in the U.S. and boasts one of the highest concentrations of breweries in America.",
        image: Culinary3,
        url: "https://www.marriott.com/en/destinations/asheville.mi",
      },
    ],
  },
  culture: {
    headline: "Explore Your Passion for Cultural and Local Excursions",
    subheader: "",
    description:
      "From neighborhood walking tours to Michelin-starred dining, so much is on the menu.",
    analyticsDesc: "Local Experiences",  
    slides: [
      {
        label: "Find travel inspiration centered on culture and style.",
        image: Culture1,
        url: "https://traveler.marriott.com/culture-and-style/",
      },
      {
        label:
          "Marriott Bonvoy Moments - Discover cultural experiences, indulge in wellness retreats, and expand your horizons.",
        image: Culture2,
        url: "https://moments.marriottbonvoy.com/en-us/moments/category/arts-lifestyle",
      },
      {
        label: "Book memorable experiences focused on arts and culture.",
        image: Culture3,
        url: "https://activities.marriott.com/north_america/usa-DJBHT2?category=1ee8f7ed-41e9-6ea2-a020-81176589e86e",
      },
      {
        label:
          "Discover Santa Fe, New Mexico - soak up creative culture in this adobe-studded city, nestled in the Sangre de Cristo foothills.",
        image: Culture4,
        url: "https://www.marriott.com/en/destinations/santa-fe.mi",
      },
    ],
  },
  music: {
    headline: "Explore Your Passion for Live Music and Concerts",
    subheader: "",
    description:
      "Turn up the volume with exclusive access to concerts and unique experiences.",
    analyticsDesc: "Moments",
    slides: [
      {
        label:
          "Marriott Bonvoy Moments - Receive VIP treatment at sold-out shows, festivals, and more.",
        image: Music1,
        url: "https://moments.marriottbonvoy.com/en-us/moments/category/entertainment",
      },
      {
        label: "7 Music Festivals Worth Traveling For",
        image: Music2,
        url: "https://traveler.marriott.com/culture-and-style/top-music-festivals/",
      },
      {
        label:
          "The Cosmopolitan of Las Vegas - Find out about upcoming Las Vegas concerts, shows and nightlife",
        image: Music3,
        url: "https://www.cosmopolitanlasvegas.com/entertainment/calendar",
      },
    ],
  },
  spa: {
    headline: "Explore Your Passion for Spa",
    subheader: "",
    description:
      "Find offers and deals for our 100s of hotels offerings serene spas, treatments, and experiences just for you.",
    analyticsDesc: "Luxury Spa",
    slides: [
      {
        label:
          "Discover serene spas, treatments, and experiences just for you.",
        image: Spa1,
        url: "https://www.marriott.com/en-us/resorts/spa-resorts",
      },
      {
        label: "Rest. Rejuvenate. Recharge.",
        image: Spa2,
        url: "https://www.marriott.com/en-us/resorts/spa-resorts",
      },
      {
        label:
          "The 17,500 square foot Spa features 15 treatment rooms, over half of which open onto private outdoor shower gardens, with two private, outdoor couples' hale (cabanas).",
        image: Spa3,
        url: "https://www.ritzcarlton.com/en/hotels/kapalua-maui/spa",
      },
      {
        label: "Find Your Bliss at the World’s Best Hotel Spas",
        image: Spa4,
        url: "https://traveler.marriott.com/spa/worlds-best-hotel-spas/",
      },
      {
        label: "W Bali - Seminyak AWAY® Spa",
        image: Spa5,
        url: "https://www.marriott.com/en-us/hotels/dpswh-w-bali-seminyak/experiences/",
      },
    ],
  },
};
