import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Heading, BtnUi } from "~/components";
import { usePassions } from "~/lib/hooks/use-passions";
import { useAcid } from "~/lib/hooks/use-acid";
import { VideoContainer } from "./VideoContainer";
import { Survey } from "./Survey";
import { sendAnalyticsSubmitEvent } from "~/utils/adobe-data-layer-utils";
import styles from "./VideoPassions.module.css";
import image from "~/assets/visual/video-passions-1.png";
import video from "~/assets/visual/video-passions-1.mov";
import { surveyData as data } from "./surveyData";

const cid = "video";

export const VideoPassions = () => {
  const { acid } = useAcid();
  const navigate = useNavigate();
  const {
    selectPassion,
    unselectPassion,
    savePassionsToServer,
    getSelectedPassionsAsArray,
  } = usePassions(cid);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const startSurvey = () => setCurrentIndex(0);
  const next = (action) => {
    const { passionId, passionName } = data[currentIndex];
    const nextIndex = currentIndex + 1;
    const nextPassion = data[nextIndex];
    const id = passionId ?? passionName.toLowerCase();

    // If "yes" clicked
    if (action === true) {
      selectPassion(id);
    }

    // If "no" clicked
    if (action === false) {
      unselectPassion(id);
    }

    if (nextPassion) {
      setCurrentIndex(nextIndex);
    } else {
      savePassionsToServer(acid, cid);
      sendAnalyticsSubmitEvent(getSelectedPassionsAsArray());
      navigate("/video/explore");
    }
  };

  if (currentIndex >= 0) {
    return <Survey data={data[currentIndex]} next={next} />;
  }

  return (
    <section className={styles.box}>
      <Heading
        type="h1"
        className={styles.header}
        text="Tap Into Your Travel Passions"
      />
      <p className={styles.subheader}>
        Give a thumbs up or down on interests and activities to get a more
        relevant experience.
      </p>
      <VideoContainer className={styles.media} src={video} poster={image} />
      <BtnUi className={styles.btn} onClick={startSurvey}>
        Get Started
      </BtnUi>
    </section>
  );
};
