import React from "react";
import classNames from "classnames";
import { Links } from "./Links";
import { linkList } from "./linkList";
import styles from "./Footer.module.css";

export const Footer = ({ className, ...props }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={classNames(styles.footer, className)} {...props}>
      <p className={styles.rights}>
        © 1996 - {currentYear} Marriott International, Inc. All rights
        reserved. Marriott Proprietary Information.
      </p>
      <div className={styles.language}>English</div>
      <Links list={linkList} />
    </footer>
  );
};
