import { useState } from "react";

export function useSessionStorage(experience, { key, value } = {}) {
  const getItem = (key) => {
    try {
      return JSON.parse(sessionStorage.getItem(`${experience}.${key}`));
    } catch (error) {
      console.error("Error reading JSON from session storage", error);
      throw error;
    }
  };

  const setItem = (key, value) => {
    sessionStorage.setItem(`${experience}.${key}`, JSON.stringify(value));
  };

  useState(() => {
    if (key && value && getItem(key) == null) {
      setItem(key, value);
    }
  });

  if (!experience) {
    throw new Error("experience is required");
  }

  return {
    getItem,
    setItem,
  };
}
