import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import BeachIcon from "../../assets/share/icon/beach.svg";
import CulinaryIcon from "../../assets/share/icon/culinary.svg";
import CultureIcon from "../../assets/share/icon/culture.svg";
import GolfIcon from "../../assets/share/icon/golf.svg";
import MusicIcon from "../../assets/share/icon/music.svg";
import SkiIcon from "../../assets/share/icon/ski.svg";
import SpaIcon from "../../assets/share/icon/spa.svg";
import styles from "./BubblePage.module.css";
import { useNavigate } from "react-router-dom";
import { usePassions } from "../../lib/hooks/use-passions";
import { useAcid } from "../../lib/hooks/use-acid";
import { sendAnalyticsSubmitEvent } from "../../utils/adobe-data-layer-utils";
import { BtnUi } from "~/components";

const passions1 = [
  { label: "Golf", icon: GolfIcon },
  { label: "Beach", icon: BeachIcon },
  { label: "Ski", icon: SkiIcon },
  { label: "Culinary", icon: CulinaryIcon },
];

const passions2 = [
  { label: "Culture", icon: CultureIcon },
  { label: "Music", icon: MusicIcon },
  { label: "Spa", icon: SpaIcon },
];

const passionsParams = [
  "golf",
  "beach",
  "ski",
  "culinary",
  "culture",
  "music",
  "spa",
];

export const JigglingCircles = () => {
  const navigate = useNavigate();
  const { acid } = useAcid();
  const {
    tapPassion,
    isAnyPassionSelected,
    getPassionValue,
    savePassionsToServer,
    getSelectedPassionsAsArray,
  } = usePassions("bubbles");

  const tapStorage = passionsParams.map((p) => getPassionValue(p));
  const circleCount = 7;
  const [activeCircles, setActiveCircles] = useState([]);
  const [initialAnimationState] = useState(() => {
    return tapStorage.map((value) => {
      switch (value) {
        case 2:
          return "tapped2idle";
        case 1:
          return "tapped1idle";
        default:
          return "idle";
      }
    });
  });
  const [initialIconAnimationState] = useState(() => {
    return tapStorage.map((value) => {
      switch (value) {
        case 2:
          return "visible2";
        case 1:
          return "visible1";
        default:
          return "hidden";
      }
    });
  });
  const controls = Array(circleCount)
    .fill()
    .map(() => useAnimation());

  const jiggleVariants = {
    idle: {
      scale: 1,
      rotate: 0,
      width: 70,
      height: 70,
      backgroundColor: "#ffffff",
      color: "var(--default-color)",
    },
    jiggle: {
      scale: [1, 1.1, 0.9, 1.05, 0.95, 1],
      rotate: [0, 5, -5, 3, -3, 0],
      transition: { duration: 1 },
    },
    tapped1idle: {
      width: 70 * 1.25,
      height: 70 * 1.25,
      padding: 2,
      backgroundColor: "var(--default-color)",
      color: "#ffffff",
    },
    tapped1: {
      width: 70 * 1.25,
      height: 70 * 1.25,
      padding: 2,
      transition: {
        type: "spring",
        stiffness: 500,
        damping: 8,
      },
      backgroundColor: "var(--default-color)",
      color: "#ffffff",
    },
    tapped2idle: {
      width: 80 * 1.5,
      height: 80 * 1.5,
      padding: 0,
      backgroundColor: "var(--default-color)",
      color: "#ffffff",
    },
    tapped2: {
      width: 80 * 1.5,
      height: 80 * 1.5,
      padding: 0,
      transition: {
        type: "spring",
        stiffness: 500,
        damping: 8,
      },
      backgroundColor: "var(--default-color)",
      color: "#ffffff",
    },
  };

  const iconVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible1: { opacity: 1, scale: 1, height: 40, width: 40 },
    visible2: { opacity: 1, scale: 1, height: 60, width: 60 },
  };

  useEffect(() => {
    const animateNextCircle = () => {
      if (activeCircles.length < 2) {
        const availableCircles = passionsParams.reduce((acc, p, index) => {
          const taps = getPassionValue(p);
          return taps === 0 ? [...acc, index] : acc;
        }, []);

        if (availableCircles.length === 0) return;

        const nextCircle =
          availableCircles[Math.floor(Math.random() * availableCircles.length)];

        setActiveCircles((prev) => [...prev, nextCircle]);
        controls[nextCircle].start("jiggle").then(() => {
          setActiveCircles((prev) => prev.filter((c) => c !== nextCircle));
        });
      }
    };

    const intervalId = setInterval(animateNextCircle, 1000);
    return () => clearInterval(intervalId);
  }, [activeCircles, controls, getPassionValue]);

  const handleTap = (index) => {
    const passion = passionsParams[index];
    const currentTaps = tapPassion(passion, 2);

    if (currentTaps === 0) {
      controls[index].start("idle");
    } else {
      controls[index].start(currentTaps === 1 ? "tapped1" : "tapped2");
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div className={styles.bubbles}>
        <motion.div className="bubble-container" layout>
          {passions1.map((p, i) => {
            const passion = passionsParams[i];
            const taps = getPassionValue(passion);
            return (
              <motion.div key={i} className="circle-wrapper" layout>
                <motion.div
                  className={`circle custom_click_track`}
                  data-custom_click_track_value={`Bubbles|${p.label}|internal`}
                  variants={jiggleVariants}
                  initial={initialAnimationState[i]}
                  animate={controls[i]}
                  onTap={() => handleTap(i)}
                >
                  <div style={{ display: taps > 0 ? "block" : "none" }}>
                    <motion.img
                      src={p.icon}
                      variants={iconVariants}
                      initial={initialIconAnimationState[i]}
                      animate={
                        taps === 1
                          ? "visible1"
                          : taps === 2
                            ? "visible2"
                            : "hidden"
                      }
                      alt=""
                    />
                  </div>
                  {p.label}
                </motion.div>
              </motion.div>
            );
          })}
        </motion.div>
        <motion.div className="bubble-container" layout>
          {passions2.map((p, i) => {
            const passion = passionsParams[i + 4];
            const taps = getPassionValue(passion);
            return (
              <motion.div key={i} className="circle-wrapper" layout>
                <motion.div
                  className={`circle custom_click_track`}
                  data-custom_click_track_value={`Bubbles|${p.label}|internal`}
                  variants={jiggleVariants}
                  initial={initialAnimationState[i + 4]}
                  animate={controls[i + 4]}
                  onTap={() => handleTap(i + 4)}
                >
                  {taps > 0 && (
                    <motion.img
                      src={p.icon}
                      variants={iconVariants}
                      initial={initialIconAnimationState[i + 4]}
                      animate={
                        taps === 1
                          ? "visible1"
                          : taps === 2
                            ? "visible2"
                            : "hidden"
                      }
                      alt=""
                    />
                  )}
                  {p.label}
                </motion.div>
              </motion.div>
            );
          })}
        </motion.div>
        <style>{`
          .bubble-container {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            width: 100%;
          }

          .circle-wrapper {
            display: block;
            padding: 4px;
          }

          .circle {
            width: 80px;
            height: 80px;
            background-color: #ffffff;
            border: 1px solid var(--default-color);
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--default-color);
            cursor: pointer;
          }
        `}</style>
      </div>

      <BtnUi
        className={`${styles.explore} custom_click_track`}
        data-custom_click_track_value={`Bubbles Footer|Save Continue|internal`}
        onClick={async () => {
          sendAnalyticsSubmitEvent(getSelectedPassionsAsArray());
          await savePassionsToServer(acid, "share");
          navigate("/share/explore");
        }}
        disabled={!isAnyPassionSelected()}
      >
        Save & Continue
      </BtnUi>
    </div>
  );
};
