import { useEffect } from "react";
import { addUserDataToAnalytics } from "../../utils/adobe-data-layer-utils";
import { useAcid } from "../../lib/hooks/use-acid";

function UserDataTracking() {
  const { acid } = useAcid();

  useEffect(() => {
    addUserDataToAnalytics(acid);
  }, [acid]);

  return null;
}

export default UserDataTracking;
