import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import { buildIFrameUrl, setCookie } from "../../utils/utils";
import { marriottCampaigns } from "./marriott-campaign-data";
import logoImage from "../../assets/images/marriott-logo.png";
import styles from "../../styles/RenderCampaign.module.css";
import LoadingPage from "../LoadingPage";
import { useAcid } from "../../lib/hooks/use-acid";
import { useCampaignParams } from "../../lib/hooks/use-campaign-params";
import { BtnBack } from "../BtnBack";

function RenderCampaign() {
  const location = useLocation();
  const { acid: uid } = useAcid();

  const [iFrameUrl, setIFrameUrl] = useState(null);
  const [marriottCampaignComponent, setMarriottCampaignComponent] =
    useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const { cSource: cSourceValue, expId: cidValue, testParam: testParamValue, channelSrc: channelSrcValue } = useCampaignParams();

  useEffect(() => {
    // resetting values once URL parameters have changed
    setLoaded(false);
    setIFrameUrl(null);
    setMarriottCampaignComponent(null);

    console.log("Retrieving Campaign parameters from the request.");
    const searchParams = new URLSearchParams(location.search);

    console.log("Value of 'cSource' param from the request: " + cSourceValue);
    console.log("Value of 'cid' param from the request: " + cidValue);
    console.log("Value of 'test' param from the request: " + testParamValue);
    console.log(
      "Value of 'channelSrc' param from the request: " + channelSrcValue,
    );

    if (cSourceValue && cidValue) {
      if (!uid) {
        // Redirect user to Login Page with the same query parameters
        const queryParamString = searchParams.toString();
        const destinationUrl = "/api/v1/web/marriott-passions-v3/login";
        console.log(
          "Redirecting user to Login Page using the following URL: " +
            destinationUrl,
        );
        // store parameters for 10 mins
        setCookie("passions_campaign", queryParamString, 10);
        window.location.href = destinationUrl;
        return;
      } else {
        if (cSourceValue.toLowerCase() === "jebbit") {
          const campaignUrl = buildIFrameUrl(
            cSourceValue,
            cidValue,
            testParamValue,
            channelSrcValue,
            uid,
          );
          setIFrameUrl(campaignUrl);
        } else if (cSourceValue.toLowerCase() === "marriott") {
          // check if it's an existing marriott campaign type
          const marriottCampaign = marriottCampaigns.campaigns.find(
            (campaign) => campaign.uuid === cidValue,
          );

          if (marriottCampaign && marriottCampaign.campaignPage) {
            console.log(
              "Marriott campaign was found by cid provided. Rendering Marriott Campaign page: " +
                marriottCampaign.campaignPage,
            );
            setMarriottCampaignComponent(marriottCampaign.campaignPage);
          } else {
            console.error(`No marriott campaign found for cid: ${cidValue}`);
          }
        } else {
          console.error(`No campaign found for cSource: ${cSourceValue}`);
        }
      }
    } else {
      console.error("Invalid or missing required parameters: cSource or cid.");
    }

    setLoaded(true);
  }, [location.search]);

  const jebbitHTMLEmbedCode = (iFrameUrl) => {
    console.log("Rendering campaign using the following URL:", iFrameUrl);
    console.log("UID:", uid);
    return `
      <iframe class="jebbit-iframe" 
              src="${iFrameUrl}&deferred=true" 
              seamless="true" 
              frameborder="0" 
              webkitallowfullscreen 
              mozallowfullscreen 
              allowfullscreen 
              style="width:100%;min-height:95vh;height:100%;">
      </iframe>
      <script>
        function embedJebbit(t) {
          function e(t) {
            var e = 'attach' === t ? window.addEventListener : window.removeEventListener;
            e('DOMContentLoaded', n, !1);
            e('load', n, !1);
            e('scroll', n, !1);
            e('resize', n, !1);
          }
          var i = document.querySelector(t);
          function n() {
            var t, e, n, o;
            e = (t = i).getBoundingClientRect();
            n = t.clientHeight / 2;
            o = t.clientWidth / 2;
            e.top >= 0 && e.left >= 0 && e.top <= (window.innerHeight || document.documentElement.clientHeight) - n && e.left <= (window.innerWidth || document.documentElement.clientWidth) - o && i.contentWindow.postMessage('startJebbitCampaign', '*');
          }
          window.addEventListener('message', function(t) {
            t && 'https://marriott.jebbit.com' === t.origin && ('jebbitSessionCreated' === t.data ? e('remove', n) : 'jebbitCampaignLoaded' === t.data ? n() : t.data && 'redirect' === t.data.action && t.data.options && t.data.options.link && (window.location.href = t.data.options.link));
          }, !1);
          e('attach');
        }
        embedJebbit('.jebbit-iframe');
      </script>
      <script>
        window.addEventListener("message", function(t) {
          try {
            if (t && "https://marriott.jebbit.com" === t.origin && t.data && "updateIframeHeight" === t.data.action && t.data.jebbitIframeHeight) {
              let e = document.querySelector(".jebbit-iframe"),
                  i = t.data.jebbitIframeHeight,
                  a = e.clientHeight;
              if (e.style.height = i + "px", i < a && (a - i > 300 || a > 1300)) {
                let o = e.getBoundingClientRect().top,
                    n = document.body.getBoundingClientRect().top;
                window.scrollTo({ top: o - n, behavior: "smooth" });
              }
            }
          } catch (r) {
            return;
          }
        });
      </script>
    `;
  };
  const iFrameCode = iFrameUrl ? jebbitHTMLEmbedCode(iFrameUrl) : "";

  if (!isLoaded) {
    return <LoadingPage />;
  }

  return (
    <div className={styles.wrapper}>
      {iFrameUrl ? (
        <div className={styles.container}>
          <div className={styles.content}>{parse(iFrameCode)}</div>
        </div>
      ) : marriottCampaignComponent ? (
        <div className={styles.wrapper}>{marriottCampaignComponent}</div>
      ) : (
        <div className={styles.container}>
          <div className={styles.content}>
            <div className="welcome-container">
              <div className="bg-white">
                <div className="bg-white-content">
                  <img className="logo" src={logoImage} />
                  <h3 className="error-title" id="login-label">
                    Sorry, we can’t find the campaign you requested.
                  </h3>
                  <p className="error-text">
                    Required parameters for the campaign are missing.
                  </p>
                  <BtnBack trackValue="Campaign Not Found Page|Back Button" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RenderCampaign;
