import React, { useEffect } from "react";
import "../styles/NotFound.css";
import { BtnBack } from "./BtnBack";
import { useLayoutStore } from "~/store";
import styles from "~/pages/NotFoundPage/NotFoundPage.module.css";
import logoImage from "../assets/images/marriott-logo.png";

function NotFound() {
  const { isResetting, setShowHeader, setContentClassName } = useLayoutStore();

  useEffect(() => {
    if (isResetting) {
      return;
    }

    setShowHeader(false);
    setContentClassName(styles.container);
  }, [isResetting]);

  return (
    <div className={"bg-white"}>
      <div className="bg-white-content">
        <img className="logo" src={logoImage} />
        <h3 className="error-title" id="login-label">
          Sorry, we can’t find the page you requested.
        </h3>
        <p className="error-text">
          The page you are looking for is no longer available.
        </p>
        <BtnBack trackValue="Not Found Page|Back Button" />
      </div>
    </div>
  );
}

export default NotFound;
