import React from "react";
import { Separator } from "./Separator";
import styles from "./Links.module.css";

const getContent = ({ label, url }) => {
  if (!url) {
    return label;
  }

  return (
    <a
      className={styles.link}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label}
    </a>
  );
};

export const Links = ({ list }) => {
  let key = 0;
  const links = list.flatMap((item, i) => {
    const notLast = i < list.length - 1;
    const link = (
      <li key={key++} className={styles.link}>
        {getContent(item)}
      </li>
    );

    return notLast ? [link, <Separator key={key++} />] : [link];
  });

  return <ul className={styles.links}>{links}</ul>;
};
