import React, { useState } from "react";
import classNames from "classnames";
import { ProgressUi } from "../ProgressUi";
import styles from "./BtnUi.module.css";

export const BtnUi = ({
  loading,
  children,
  className,
  progressText,
  showProgressText = true,
  onClick: cb = () => {},
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // The state can be external with prop "loading"
  const showLoader = loading !== undefined ? loading : isLoading;
  const onClick = async (...args) => {
    if (showLoader) {
      return;
    }

    setIsLoading(true);
    await cb(...args);
    setIsLoading(false);
  };

  return (
    <button
      type="button"
      {...props}
      className={classNames(className, { [styles.loading]: showLoader })}
      onClick={onClick}
    >
      {showLoader ? (
        <ProgressUi text={progressText} showText={showProgressText} />
      ) : (
        children
      )}
    </button>
  );
};
