export const exploreData = {
  golf: {
    slides: [
      {
        headline: "Popular Golf Resorts",
        label: "Find beautiful places to stay and play.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/ahnrz-golf-course-50669716?wid=316&fit=constrain",
        url: "https://www.marriott.com/en-us/resorts/golf-resorts",
        analyticsDesc: "Golf Resort",
      },
    ],
  },
  beach: {
    slides: [
      {
        headline: "Discover Beach Resorts",
        label:
          "Refreshing swims, beach walks and sun-kissed resort days are here.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/xr-mlexr-whale-bar-aerial-37919-47743?wid=316&fit=constrain",
        url: "https://www.marriott.com/en-us/resorts/beach-resorts",
        analyticsDesc: "Beach Resort",
      },
    ],
  },
  ski: {
    slides: [
      {
        headline: "Top Ski Resorts",
        label: "Seek snowy peaks at these amazing hotels.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/whistler-village-whistler-british-columbia-canada?wid=316&fit=constrain",
        url: "https://www.marriott.com/en-us/resorts/ski-resorts",
        analyticsDesc: "Ski Resort",
      },
    ],
  },
  culinary: {
    slides: [
      {
        headline: "Culinary Experiences",
        label: "Indulge in culinary experiences around the globe.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/si-yyztc-food-drinks-38936?wid=316&fit=constrain",
        url: "https://www.marriott.com/en-us/dining",
        analyticsDesc: "Wine and Dine",
      },
    ],
  },
  culture: {
    slides: [
      {
        headline: "Connect to Culture",
        label: "Find travel inspiration centered on culture and style.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/md-parmd-paris---36886-56497?wid=316&fit=constrain",
        url: "https://traveler.marriott.com/culture-and-style",
        analyticsDesc: "Local Experiences",
      },
    ],
  },
  music: {
    slides: [
      {
        headline: "Marriott Bonvoy Moments",
        label: "Receive VIP treatment at sold-out shows, festivals and more.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/br-nycrt-enjoy-a-concert-at-msg-20887?wid=316&fit=constrain",
        url: "https://moments.marriottbonvoy.com/en-us/moments/category/entertainment",
        analyticsDesc: "Moments",
      },
    ],
  },
  spa: {
    slides: [
      {
        headline: "Rest. Rejuvenate. Recharge.",
        label:
          "No matter how you prefer to have some R&R, we have spa and wellness spots just for you.",
        image:
          "https://cache.marriott.com/is/image/marriotts7prod/br-sjubr-elemara-spa-38009-22237?wid=316&fit=constrain",
        url: "https://www.marriott.com/en-us/resorts/spa-resorts",
        analyticsDesc: "Luxury Spa",
      },
    ],
  },
};
